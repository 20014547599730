export default {
    data(){
        return {
            _app_name : process.env.VUE_APP_NAME ?? 'Payment Software Sekolah',
        }
    },

    methods : {
        humanDate : (date, {withTime = true} = {}) => {
            if(!date) return 'Invalid Date';
            
            let d = new Date(date);
            
            const options  = {month : 'long'};
            
            let year = d.getFullYear();
            let monthName = new Intl.DateTimeFormat("id", options).format(d);
            let day = d.getDate();
            let hours = d.getHours().toString().padStart(2, '0');
            let minutes = d.getMinutes().toString().padStart(2, '0');
            let seconds = d.getSeconds().toString().padStart(2, '0');

            let stack1 = [day, monthName, year];
            let stack2 = [hours, minutes, seconds];

            let dateString = '';
            dateString += stack1.join(' ');
            
            if(withTime) dateString += ' ' + stack2.join(':');
            return dateString;
        },

        rupiah : (number) => new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits : 0
        }).format(number)
    }
}