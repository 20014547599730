<template>
  <div class="container container_shadow p-0">

  <router-view v-slot="{ Component, route }">
    <!-- Use any custom transition and  to `fade` -->
    <transition :name="route?.meta?.transition ?? 'slide-left'" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style scoped>
  .container {
    max-width: 750px;
  }
</style>
<style>
  
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: all 0.25s ease-in-out;
  }

  .slide-right-enter-to {
    position: absolute;
    right: 0;
  }

  .slide-right-enter-from {
    position: absolute;
    right: -100%;
  }

  .slide-right-leave-to {
    position: absolute;
    left: -100%;
  }

  .slide-right-leave-from {
    position: absolute;
    left: 0;
  }

  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: all 0.25s ease-in-out;
  }

  .slide-left-enter-to {
    position: absolute;
    left: 0;
  }

  .slide-left-enter-from {
    position: absolute;
    left: -100%;
  }

  .slide-left-leave-to {
    position: absolute;
    right: -100%;
  }

  .slide-left-leave-from {
    position: absolute;
    right: 0;
  }
</style>

<style>
  .v3dp__input_wrapper{
      position: relative !important;
  }

  .v3dp__clearable{
      position: absolute !important;
      top: 0.5rem !important;
      right: 1rem !important;
      width: 1rem !important;
      left: unset !important;
      display: block;
  }
  .v3dp__clearable i {
      float: right;
  }

  .fs-custom-6{
    font-size: 0.95rem;
  }

  .card-bg-transparent{
    background-color: transparent !important;
  }
</style>