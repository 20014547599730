// import store from "@/state/store";

export default [
  {
    path: "/",
    name: "home",
    meta: { force_transition: 'slide-left' },
    component: () => import("./views/wallet/home"),
  },
  {
    path: "/qr",
    name: "qr",
    component: () => import("./views/wallet/qr"),
  },
  {
    path: "/wallet/pin",
    name: "wallet_pin",
    component: () => import("./views/wallet/unlock-wallet"),
  },
  {
    path: "/wallet/phrase",
    name: "wallet_phrase",
    component: () => import("./views/wallet/phrase"),
  },
  {
    path: "/wallet/pin/set",
    name: "wallet_pin_set",
    component: () => import("./views/wallet/set-pin"),
  },
  {
    path: "/wallet/pin/forgot",
    name: "wallet_pin_forgot",
    component: () => import("./views/wallet/forgot-pin"),
  },
  {
    path: "/wallet/pin/forgot/options",
    name: "wallet_pin_forgot_options",
    component: () => import("./views/wallet/options-forgot-pin"),
  },
  {
    path: "/wallet/pin/forgot/success",
    name: "wallet_pin_forgot_success",
    component: () => import("./views/wallet/renew-pin-success"),
  },
  {
    path: "/channels",
    name: "channels",
    component: () => import("./views/wallet/channel"),
  },
  {
    path: "/channel/:channel",
    name: "channel_detail",
    component: () => import("./views/wallet/channel-detail"),
  },
  {
    path: "/transaction/history",
    name: "transaction_history",
    component: () => import("./views/wallet/transaction-history"),
  },
  {
    path: "/transaction/history/filter",
    name: "transaction_history_filter",
    component: () => import("./views/wallet/transaction-history-filter"),
  },
  {
    path: "/transaction/history/detail",
    name: "transaction_detail",
    component: () => import("./views/wallet/transaction-detail"),
  },
  {
		path: '/500',
		name: '500',
		component : () => import('./views/utility/500')
	},
  {
		path: '/404',
		name: '404',
		component : () => import('./views/utility/404')
	},
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("./views/account/login"),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },

  {
		path: '/:catchAll(.*)',
		redirect: '404',
	},
];